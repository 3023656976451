<template>
    <div>
        <v-row align="center" class="mb-3">
            <v-spacer />
            <v-col cols="12" md="3">
                <v-text-field
                    v-debounce:500ms="getItems"
                    v-model="$global.state.filter.search"
                    label="ابحث هنا"
                    color="indigo"
                    hide-details
                    prepend-inner-icon="search"
                    outlined
                    dense
                    filled
                />
            </v-col>

            <v-col cols="12" md="3">
                <v-autocomplete
                    @input="getItems()"
                    clearable
                    v-model="$global.state.provinceId"
                    item-text="name"
                    item-value="id"
                    :items="$global.state.provinces"
                    color="indigo"
                    label="المحافظة"
                    :loading="$global.state.loading"
                    no-data-text="لا توجد بيانات"
                    hide-details
                    prepend-inner-icon="place"
                    outlined
                    dense
                    filled
                />
            </v-col>
        </v-row>

        <v-card id="card">
            <v-data-table
                class="mytable"
                :items-per-page="$global.state.filter.pageSize"
                :items="$global.state.clients"
                :loading="$global.state.loading"
                :headers="headers"
                :search="$global.state.filter.search"
                hide-default-footer
                loading-text="جاري تحميل البيانات"
                no-data-text="لا توجد بيانات"
                :id="$vuetify.theme.dark ? 'row_colors_dark' : 'row_colors_light'"
            >
                <template v-slot:item.id="{ item }">
                    <span>{{ $global.state.clients.indexOf(item) + 1 }}</span>
                </template>

                <template v-slot:item.phoneNumber="{item}">
                    <div dir="ltr">{{item.phoneNumber | phone}}</div>
                </template>

                <template v-slot:item.isBlock="{item}">
                    <v-chip v-if="item.isBlock" small dark color="red lighten-1">محضور</v-chip>
                    <v-chip v-else dark small color="green lighten-1">فعال</v-chip>
                </template>
                
                <template v-slot:item.rating="{item}">
                    <star-rating v-model="item.rating" inactive-color="#FFFFFF" :padding="1" :rounded-corners=true
                    :border-width="2" :increment="0.01" :fixed-points="2" :star-size="20" :show-rating="false"
                    :rtl="true" :max-rating=5 :read-only=true></star-rating>

                </template>

                <template v-slot:item.created="{ item }">
                    <div>{{new Date(item.created).toISOString().substring(0,10)}}</div>
                </template>
            </v-data-table>
        </v-card>
        <div class="d-flex justify-space-between py-3">
            <v-spacer></v-spacer>
            <Pagination />
        </div>
    </div>
</template>

<script>
import StarRating from 'vue-star-rating'
export default {
    components: {
        StarRating
    },
    data() {
        return {
            headers: [
                {
                    text: "#",
                    value: "id",
                },

                {
                    text: "الإسم الكامل",
                    value: "fullName",
                },

                {
                    text: "رقم الهاتف",
                    value: "phoneNumber",
                    align: "right",
                },
                {
                    text: "الحالة",
                    value: "isBlock",
                },
                {
                    text: " المحافظة",
                    value: "province.name",
                },
                {
                    text: " العنوان",
                    value: "addres",
                },
                {
                    text: "التقييم",
                    value: "rating",
                    align: "right",
                },
                {
                    text: "الطلبات",
                    value: "ordersCount",
                    align: "center",
                },
                {
                    text: "تاريخ التسجيل",
                    value: "created",
                },
            ],
        };
    },

    mounted() {
        this.getItems();
        this.$eventBus.$on(`refresh`, () => {
            this.getItems();
        });
    },

    beforeDestroy() {
        this.$eventBus.$off(`refresh`);
    },

    methods: {
        getItems() {
            this.$global.dispatch(
                `get${this.$route.meta.endPoint}`,
                this.$route.meta.role
            );
        },
    },
};
</script>
